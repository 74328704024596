.rounder-btn {
     background-color: #585858;
     box-shadow: rgba(19, 23, 24, 0.5) 0px 0px 5px 0px;
     border: none;
     border-radius: 50px;
     min-width: 150px;
     height: 35px;
     line-height: 35px;
     font-size: .8rem;
     text-transform: uppercase;
     color: #d5d5d5;
     padding: 0 1.5rem;
     transition: all .25s;
     &:hover {
       background-color: #4b4b4b ;
       box-shadow: rgba(19, 23, 24, 0.5) 3px 3px 10px 0px;
       color: #ffffff;
     }
     &:disabled  {
          color: #3a3a3a !important;
          cursor: not-allowed;
          &:hover{
               color: rgba(16, 16, 16, 0.3);
               background-color: #585858 ;
          }
     }
}
.info-text-modal {
     color: #b9bbbe;
}
.create-folder-text {
     user-select: text ;
}
.path-folder::placeholder {
     color:#aeaeae !important ;
}