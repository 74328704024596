.AppContent{
  .tabContent {
    overflow: hidden;
    width: 100%;
  }
  .nav-tabs {
    margin-right: 1rem;
  }
  .nav-tabs .nav-link {
     padding: 1rem 1.5rem .75rem 1.5rem;
     min-width: 200px;
     border: none;
     font-weight: bold;
     border-radius: 5px;
     font-size: 15px;
     margin-left: 1rem;
     color: #c7c7c7;
   }
   .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
     color: #3dd598;
     background-color: #383838;
     border-radius: 5px;
   }
   .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active:hover {
     color: #3dd598;
     background-color: #263c33;
   }
   .nav-link:focus, .nav-link:hover {
     color: #FFF;
     background-color: #383838;
     border-radius: 5px;
   }
   .nav.nav-tabs{
     background-color: #202124;
     padding-top: .5rem;
     padding-bottom: 10px;
     border: none;
   }
 }