.analyzed-scene {
     background-color: #343434;
     .title{
          background-color: #212121;
  
     }
     .fixed-bottom {
          background-color: #212121;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
     }
     .tab-content>.tab-pane {
          height: 100%;
      }
     &.sceene-from-server{
          z-index: 10000;
          position: fixed;
          top: 35px;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.75);
 
          > div {
               position: relative;
               top: 50%;
               left: 50%;
               right: auto;
               bottom: auto;
               margin-right: -50%;
               transform: translate(-50%, -50%);
               height: calc(100vh - 35px - 10%);
               width: 1100px;
               border-radius: 5px;
               -webkit-backdrop-filter: saturate(180%) blur(20px);
               backdrop-filter: saturate(180%) blur(20px);
               background: rgba(52, 52, 52, 0.5);
          }
          .title{
               background-color: #212121;
               border-radius: 5px 5px 0px 0px;
       
          }
          #RenderNowNav {
               border-radius: 5px 5px 0px 0px;
          }
          .close-back-btn:hover {
               background-color: #444444;
          }
          .RenderSetting {
               height: calc(100% - 38px - 38px - 45px) ;
          }
     }
}
#RenderNowNav {
     background-color: #303030;
}
.render-now {
     font-size: .9rem;
     .RenderSetting {
          padding: 1rem;
          height: calc(100vh - 38px - 38px - 46px) ;
          overflow-y: scroll;
          overflow-x: hidden;
               &::-webkit-scrollbar {
                    width: 16px;
                    height: 16px;
               }
               &::-webkit-scrollbar-corner {
                    background-color: transparent;
               }
               &::-webkit-scrollbar-thumb {
                    background-color: #555555;
                    min-height: 40px;
               }
               &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
                    border: 4px solid transparent;
                    background-clip: padding-box;
                    border-radius: 8px;
               }
               &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
                    border: 4px solid transparent;
                    background-clip: padding-box;
                    border-radius: 8px;
               }
               &::-webkit-scrollbar-track {
                    background-color: transparent;
               }
          }
     .form-control {
          background-color: transparent;
          color: #FFF;
          font-size: .8rem;
          border-radius: 5px;
          padding: .5rem 1rem;
          border: 1px solid #8d8d8d;
     }
     .form-control:disabled, .form-control[readonly] {
          background-color:  transparent !important;
          opacity: 1;
     }
     .form-control::placeholder {
          color: #FFF;
     }
     .form-control:focus {
          color: #FFF;
          background-color: #545454;
          box-shadow: none;
          border: 1px solid #8d8d8d;
     }
     .accordion-body {
          border-top: 0px !important;
          border: 1px solid #8d8d8d;
          border-radius: 0 0 5px 5px;
          min-height: 200px;
          overflow-y: scroll ;
          &::-webkit-scrollbar {
               width: 16px;
               height: 16px;
          }
          &::-webkit-scrollbar-corner {
               background-color: transparent;
          }
          &::-webkit-scrollbar-thumb {
               background-color: #555555;
               min-height: 40px;
          }
          &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
               border: 4px solid transparent;
               background-clip: padding-box;
               border-radius: 8px;
          }
          &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
               border: 4px solid transparent;
               background-clip: padding-box;
               border-radius: 8px;
          }
          &::-webkit-scrollbar-track {
               background-color: transparent;
          }
     }
     .accordion-item {
          background-color: transparent;
          border: 0px;
     }
     .accordion-item:first-of-type .accordion-button {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
     }
     .accordion-button:not(.collapsed) {
          color: inherit;
          background-color: transparent;
          box-shadow: none;
          border: 1px solid #8d8d8d;
          border-radius: 5px 5px 0 0 !important;
          font-size: .85rem;
          font-weight: 600;
          margin-top: 1rem;
     }
     .accordion-button {
          color: inherit;
          background-color: transparent;
          box-shadow: none;
          border: 1px solid #8d8d8d;
          border-radius: 5px;
          padding: .5rem .75rem;
          font-size: .85rem;
          font-weight: 600;
          margin-top: 1rem; 
     }
     .accordion-item:last-of-type .accordion-button.collapsed {
          border-radius: 5px;
     }
     .accordion-button:focus {
          box-shadow: none;
          border: 1px solid #8d8d8d;
     
     }
     .accordion-button::after {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
          
     }
     .input-group-text {
          background-color: transparent;
          font-size: .85rem;
          font-weight: 600;
          color: #FFF;
          border: 0 !important;
     }

 
     .form-select {
          border: 1px solid #8d8d8d;
          border-radius: 5px !important;
          background-color: transparent;
          font-size: .85rem;
          padding: .47rem 1rem .47rem 1rem ;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
          color: #FFF;
     }
     .form-select:focus {
          box-shadow: none;
          background-color: #545454;
          border: 1px solid #8d8d8d !important;
     }
     option {
          background-color: #3a3a3a;
     }
     .start-btn {
          background-color: #429585;
          box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2) ;
          border-radius: 10px;
          color: #FFF;
          font-size: .8rem;
     }
     .start-btn:focus {
          box-shadow: none;
     }
     .start-btn:hover {
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5) ;
          background-color: #50ad9b;
     }
     .select-button {
          color: #FFF;
          border: 1px solid #8d8d8d;
     
     }
     .card {     
          background-color: transparent;
          border: 1px solid #8d8d8d;
     }
     .card-header {
          border-bottom: 1px solid #8d8d8d;
          background-color: #5a5a5a;
     
     }
     .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
     border-bottom: #3dd598 1px solid;
     background-color: transparent;
     border-radius: 0;
     color: #3dd598;
     }
     .nav-link:focus, .nav-link:hover {
     color: #3dd598;
     }
  
}

