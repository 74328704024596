.bottom-bar {
     height: 25px;
     width: calc(100% - 1rem);
     padding-left: .5rem;
     .notification-text {
          white-space: nowrap; 
          overflow: hidden;
          text-overflow: ellipsis; 

     }
     .notificaiton-btn {
          cursor: pointer;
     }
   
}