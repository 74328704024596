.plugins-manager {
     padding-top: .75rem;
     background-color: #424242;
     border-radius: 5px;
     -webkit-animation: .35s ease 0s normal forwards 1 fadein;
	animation: .35s ease 0s normal forwards 1 fadein;
     margin: 0 1rem;
     .plugin-topbar {
          margin: 0 .75rem ;
          .alert {
               user-select: text;
          }
          .plugin-button {
               padding:.5rem 0rem;
               button {
                    background-color: #585858;
                    box-shadow: rgba(19, 23, 24, 0.5) 0px 0px 5px 0px;
                    border: none;
                    font-size: .8rem;
                    text-transform: uppercase;
                    color: #e4e4e4;
                    transition: all .25s;
                    margin-right: .5rem;
                    &:hover {
                    background-color: #4b4b4b ;
                    box-shadow: rgba(19, 23, 24, 0.5) 3px 3px 10px 0px !important;
                    color: #ffffff;
                    }
               }
          }
     }
     .list-plugins{
          min-height: calc(100vh - 295px);
          overflow: hidden;
          overflow-y: scroll;
          &::-webkit-scrollbar {
               width: 16px;
               height: 16px;
          }
          &::-webkit-scrollbar-corner {
               background-color: transparent;
          }
          &::-webkit-scrollbar-thumb {
               background-color: #555555;
               min-height: 40px;
          }
          &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
               border: 4px solid transparent;
               background-clip: padding-box;
               border-radius: 8px;
          }
          &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
               border: 4px solid transparent;
               background-clip: padding-box;
               border-radius: 8px;
          }
          &::-webkit-scrollbar-track {
               background-color: transparent;
          }
          .plugin-item {
               @media (min-width: 1200px) {
                    width: calc(50% - .5rem * 2);
                    
               }
               @media (min-width: 1600px) {
                    width: calc(25% - .5rem * 2);
               }
               background-color: #293134;
               margin: .5rem;
               border-radius: 5px;
               box-shadow: rgba(19, 23, 24, 0.5) 0px 0px 5px 0px;
               .plugin-item-body {
                    padding: 1rem;
                    display: flex;
                    .plugin-thumbnail {
                         margin-top: auto;
                         margin-bottom: auto;
                         margin-right: 1rem;
                         > * {
                              background-size: cover;
                              background-repeat: no-repeat;
                              width: 2.5rem;
                              height: 2.5rem;
                         }
                    }
                    .plugin-title {
                         margin-bottom: .25rem;
                         text-transform: uppercase;
                         color: #FFF;
                    }
                    .plugin-detail {
                         color: #d0d0d0;
                         font-size: .9rem;
                    }
                    .preference-button {
                         box-shadow: rgba(19, 23, 24, 0.5) 0px 0px 5px 0px;
                         background-color: #1b1b1b;
                         border: none;
                         border-radius: 5px;
                         width: 100px;
                         height: 35px;
                         line-height: 23px;
                         font-size: .9rem;
                         &:hover {
                              background-color: #272727 !important;
                              border:none;
                         }
                         &:focus {
                              outline: 0;
                              box-shadow: none;
                         }
                    }
               }
               
          }
     }
}