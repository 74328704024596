.title-bar {
    height: 35px;
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10001;
    .title-brand {
        -webkit-app-region: drag;
        .logo-text {
            font-size: .9rem;
            font-weight: 700;
            &:hover {
                transition:.25s;
                color: #3DD598;
            }
        }
        .logo-title-bar-text {
            &-super {
                @extend .logo-text;
                color: #fff;
            }
            &-renders {
                @extend .logo-text;
                color: #3DD598;
            }

        }
    }

    .dropdown-menu {
        z-index: 10003;
    }

    .topup {
        border-radius: 50px;
        border: 0;
        height: 1rem;
        width: 1rem;
        padding: 0px;
        background-color: #1e89db;
        font: initial;
        &:focus {
            outline: 0;
            box-shadow: none;
        }
        &:hover {
            background-color: #2199f5;
        }
    }

    .titlebar-button{
        font:icon;
        width: 45px;
        height: 30px;
        border: none;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        transition: .2s;
        background-repeat: no-repeat;
        background-position: center;
        color: #d3d3d3;
        padding: 2px 0px 0px 0px;
        font-size: .9rem !important;
        &:active {
            background-color: #272C36;
        }
        &:hover {
            background-color: #3d3d3d;
        }
    }
    .closeBtn, .maximizeBtn, .minimizeBtn {
        width: 35px !important;
        height: 25px;
        padding: 0;
    }
    .closeBtn {
        &:hover {
            background-color: #cc0000;
        } 
    }
    .calculatorBtn {
        &:hover {
            background-color: #3dd59851;
        } 
    }
    .helpBtn {
        width: auto;
        padding: 0 .5rem;
        font-size: .85rem;
        padding-top: 5px;
        background-color: #ff000051;

        font:inherit;
      
    }
    .userBtn {
        width: auto;
        max-width: 250px;
        min-width: 170px;
        padding: 0 .5rem;
        font-size: .85rem;
        padding-top: 5px;
        text-align: left;
        font:inherit;
        .user-name {
            max-width: 200px;
        }
    }
    
}


