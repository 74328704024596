.account-title-bar {
     .dropdown-menu {
          width: 100%;
          border-radius: 0 0 5px 5px;
          border: none;
          background-color: rgba(61, 61, 61, 0.5);
          -webkit-backdrop-filter: saturate(180%) blur(20px);
          backdrop-filter: saturate(180%) blur(20px);
          .dropdown-item {
               text-transform: uppercase;
               color: #dddddd;
               padding: .5rem;
          }
     }
     .current-credit{
          font-size: .7rem;
          color: #20c997;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          position: relative;
          bottom: 1px;
     }
}