body {
  font-family: "Josefin Sans",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 0.95rem;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
}
body.dark {
  background-color: #202124;
  color: #ededed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
  color: #3dd598;
}
.bg-success-bright {
  background: rgba(61, 213, 152, 0.125) !important;
}
::selection {
  color: #000000;
  background: #3dd598;
}
.btn {
  color: #ededed;
}
.btn:hover {
  box-shadow: none !important;
  color: #FFF;
}
.btn:focus {
  box-shadow: none !important;
}
.btn:active {
  box-shadow: none;

}