// a,button,li,span,i,.pro-inner-item {
//   cursor : default !important;
// }
.App{
  margin-top: 35px;
  -webkit-animation: .25s ease 0s normal forwards 1 fadein;
	animation: .25s ease 0s normal forwards 1 fadein;
}
@keyframes fadein{
	0% { opacity:0; }
	66% { opacity:0; }
	100% { opacity:1;}
 }
 
 @-webkit-keyframes fadein{
	0% { opacity:0; }
	66% { opacity:0; }
	100% { opacity:1;}
}
.logo {
  -webkit-animation: .25s ease 0s normal forwards 1 cubic-bezier(0.075, 0.82, 0.165, 1);
	animation: .25s ease 0s normal forwards 1 cubic-bezier(0.075, 0.82, 0.165, 1);
}
option {
  background-color: #3a3a3a;
  font:caption;
}
optgroup {
  background-color: #2e2e2e!important;
  font:caption;
}
select {
  &::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }
  &::-webkit-scrollbar-corner {
      background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
      background-color: #a7a7a7;
      min-height: 40px;
  }
  &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
      border: 4px solid transparent;
      background-clip: padding-box;
      border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
      border: 4px solid transparent;
      background-clip: padding-box;
      border-radius: 8px;
  }
  &::-webkit-scrollbar-track {
      background-color: transparent;
  }
}


a {
  color: #FFF;
  text-decoration : none;
  &:hover {
       color: #3dd598;
  }
}
.text-success {
  color: #3dd598 !important;
}
.badge {
  font-weight: 400;
  padding-top: 5px;
  
}
.drag{
  display: flex;
  flex:1;
  -webkit-user-select: none;
  user-select: none;
  -webkit-app-region: drag;
}
.form-control {
  color: #ededed;
  border: none;
  background-color: #545454;
  font-size: .9rem;
  transition: all .25s;
  &::placeholder  {
    color: #ededed;
  }
  &:hover {
    background-color: #545454;
  }
  &:focus {
    color: #ededed;
    background-color: #3d3d3d;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
    transition: all .25s;
  }
}

.form-floating>label {
  color: #ededed;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  color: #ededed;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #545454;
  opacity: 1;
}

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  background-color: #3dd598;
  color: #242424;
}
.dropdown-menu {
  min-width: 6rem;
  padding: .25rem 0;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}

.dropdown-item {
  font-size: .8rem;
  font-weight: 500;
  padding: .25rem .5rem;
}
.btn,input {
  font-weight: 500;
}
.card {
  background-color: #383838;
  border: 0;
}
.form-check {
  .form-check-input {
    cursor: pointer;
    &:checked {
      background-color: #3dd598;
      border-color: #3dd598;
    }
    &:focus {
      box-shadow: none;
      border-color:transparent;
    }

  }
  .form-check-label {
    cursor: pointer;
    padding-top: 2px;
  }
  
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23000'/%3e%3c/svg%3e") !important
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #293134;
  border-color: rgba(0,0,0,.15);
}

.form-select {
  background-color: #545454;
  border: none;
  color: #FFF;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  &:focus {
    border-color: transparent !important ;
    box-shadow :none !important
  }
}
.button-rounder {
  background-color: transparent ;
  color: #3dd598 !important;
  border:  1px solid #3dd598!important;
  border-radius: 50px;
  width: 150px;
  height: 30px;
  line-height: 30px;
  font-size: .8rem;
  text-transform: uppercase;
  &:hover {
    background-color: #3dd598 ;
    transition: all .25s;
    color: #222222 !important;
  }
}
.spinner-border.fast {
  -webkit-animation: .4s linear infinite spinner-border !important;
    animation: .4s linear infinite spinner-border !important;
}
.tippy-box[data-animation=fade][data-state=hidden]{opacity:0}[data-tippy-root]{max-width:calc(100vw - 10px)}.tippy-box{box-shadow: 2px 2px 10px 0px #00000050;position:relative;background-color:#333;color:#fff;border-radius:5px;font-size:14px;line-height:1.4;outline:0;transition-property:transform,visibility,opacity}.tippy-box[data-placement^=top]>.tippy-arrow{bottom:0}.tippy-box[data-placement^=top]>.tippy-arrow:before{bottom:-7px;left:0;border-width:8px 8px 0;border-top-color:initial;transform-origin:center top}.tippy-box[data-placement^=bottom]>.tippy-arrow{top:0}.tippy-box[data-placement^=bottom]>.tippy-arrow:before{top:-7px;left:0;border-width:0 8px 8px;border-bottom-color:initial;transform-origin:center bottom}.tippy-box[data-placement^=left]>.tippy-arrow{right:0}.tippy-box[data-placement^=left]>.tippy-arrow:before{border-width:8px 0 8px 8px;border-left-color:initial;right:-7px;transform-origin:center left}.tippy-box[data-placement^=right]>.tippy-arrow{left:0}.tippy-box[data-placement^=right]>.tippy-arrow:before{left:-7px;border-width:8px 8px 8px 0;border-right-color:initial;transform-origin:center right}.tippy-box[data-inertia][data-state=visible]{transition-timing-function:cubic-bezier(.54,1.5,.38,1.11)}.tippy-arrow{width:16px;height:16px;color:#333}.tippy-arrow:before{content:"";position:absolute;border-color:transparent;border-style:solid}.tippy-content{position:relative;padding:5px 9px;z-index:1}
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  .close-button {
       position: absolute;
       top: 16px;
       right: 16px;
  }
  .modal-header {
       padding: 24px 16px;
       border: none;
       display: block;
       >div > p{
            color: #b9bbbe;
       }
  }
  .modal-body {
       padding: 0px 16px;
  }
  .form-label {
       color: #b9bbbe;
       font-size: 12px;
       line-height: 16px;
       font-weight: 600;
       text-transform: uppercase;
  }
  .modal-footer {
       border: none;
       background-color: rgba(0, 0, 0, 0.3);
  }
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}
.disable {
  cursor: not-allowed !important;
}
.link {
  cursor: pointer!important;
  transition: .25s all;
  &:hover {
    color: #3dd598 !important;
  }
  &:active {
    color: #3dd598 !important;
  }
}