.screen-modal {
    position: absolute;
    z-index: 1031;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

// fadeOut keyframes erease in

@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1.02);
    }
}

// fadein keyframes
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(1.05);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

// create fade out animation
.screen-modal-fade-out {
    animation: fadeOut 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    animation-fill-mode: forwards;
}

// create fade in animation
.screen-modal-fade-in {
    animation: fadeIn 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    animation-fill-mode: backwards;
}