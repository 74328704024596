
.file-manager {
     .quick-tips-show {
          transition: all .25s ;
          height: calc(100vh - 390px);
     }
     .quick-tips-hide {
          transition: all .25s ;
          height: calc(100vh - 300px);
     }
     .bottom-bar {
          height: 23px;
          width: 100%;
          padding: 0;
          border-top: 1px solid #202124 ;
          overflow: hidden;
     }
     .form-check-input {
          margin-top: .3em;
     }
     >div {

          overflow: hidden;
          overflow-y: scroll;
          &::-webkit-scrollbar {
               width: 16px;
               height: 16px;
          }
          &::-webkit-scrollbar-corner {
               background-color: transparent;
          }
          &::-webkit-scrollbar-thumb {
               background-color: #555555;
               min-height: 40px;
            
          }
          &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
               border: 4px solid transparent;
               background-clip: padding-box;
               border-radius: 8px;
          }
          &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
               border: 4px solid transparent;
               background-clip: padding-box;
               border-radius: 8px;
          }
          &::-webkit-scrollbar-track {
               background-color: transparent;
          }
     }
     .item {
          &.folder {
               cursor: pointer;
          }
     }
     .grid-view {
          >div>span {
               display: flex;
               flex-wrap: wrap;
          }
          .item {
               -webkit-animation: .35s ease 0s normal forwards 1 fadein;
               animation: .35s ease 0s normal forwards 1 fadein;
               @media (min-width: 1200px) {
                    width: calc(25% - .5rem * 2);
                    
               }
               @media (min-width: 1600px) {
                    width: calc(20% - .5rem * 2);
               }
               background-color: #293134;
               margin: .5rem;
               padding: 0;
               border-radius: 5px;
               box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25);
               .form-check {
                    display: none;
               }
            
               .item-name-tippy {
                    word-break:break-all;
                    width: 100px;
                    color: #000;
               }
               .item-body {
                    padding: .5rem;
                    width: calc(100% - 40px);
               }
               &:hover {
                    background-color: #3b6d66;
                    color: #FFF;
                    transition: all .25s;
                    box-shadow: 2px 5px 15px 0px rgba(0, 0, 0, 0.35);
                    z-index: 1400;
                    .more-button { 
                         opacity: 1;
                    }
                    .dropdown-menu.show {
                         display: block !important;
                    }  
               }
           
               .item-thumbnail {
                    border-radius: 50px;
                    > * {
                         background-size: cover;
                         background-repeat: no-repeat;
                         width: 2rem;
                         height: 2rem;
                    }
               }
               .item-name {
                    margin-left: .5rem;
                    font-size: .85rem;
                    padding-top: 2.5px;
                    margin-top: auto;
                    margin-bottom: auto;
                    white-space: nowrap; 
                    width: 100%; 
                    overflow: hidden;
                    text-overflow: ellipsis; 
               }
               
          }
          .item-button-group {
     
               .more-button {
                    height: 1.75rem;
                    width: 1.75rem;
                    padding: 0;
                    justify-content: center;
                    border-radius: 50%;
                    opacity: 0;
                    transition: all .25s;
                    font: icon;
                    &:hover {
                         background-color: #212529;
                    }
                    &:focus {
                         background-color: #212529;
                         outline: 0;
                         box-shadow: none;
                    }
                    
               }
               .dropdown-menu.show {
                    display: none;
               }  
          }
       
          .list-info {
               display: none;
          }
     }
     .list-view {
          
          >div>span {
               display: flex;
               flex-wrap: wrap;
          }
     
          .item {
               -webkit-animation: .35s ease 0s normal forwards 1 fadein;
               animation: .35s ease 0s normal forwards 1 fadein;
               width: calc(100% - 1rem);
               margin: .5rem;
               padding: 0;
               border-radius: 5px;
               .form-check {
                    margin-left: 1rem;
                    cursor: default !important;
               }
               &.other {
                    cursor: default !important;
               }
               .item-name-tippy {
                    word-break:break-all;
                    width: 100px;
                    color: #000;
               }
               .item-body {
                    padding: .5rem;
                    width: calc(100% - 44px);
               }
               &:hover {
                    background-color: #3b6d66;
                    z-index: 1000;
                    color: #FFF;
                    transition: all .25s ;
                    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.35);
                    .more-button { 
                         opacity: 1;
                    }
                    .dropdown-menu.show {
                         display: block !important;
                    }
               }
               .more-button.show {
                    display: block !important;
               }
               .item-thumbnail {
                    border-radius: 50px;
                    > * {
                         background-size: cover;
                         background-repeat: no-repeat;
                         width: 2rem;
                         height: 2rem;
                    }
      
               }
               .dropdown-menu.show {
                    display: none;
               }
               .item-name {
                    margin-left: .5rem;
                    font-size: .85rem;
                    padding-top: 2.5px;
                    margin-top: auto;
                    margin-bottom: auto;
                    white-space: nowrap; 
                    width: 40%; 
                    overflow: hidden;
                    text-overflow: ellipsis; 
               }
               .list-info {
                    display: flex;
                    width: 20%;
                    > div {
                         overflow: hidden;
                         text-overflow: ellipsis; 
                    }
               }
               
          }
          .item-button-group {
     
               .more-button {
                    margin-right: 1rem;
                    height: 1.75rem;
                    width: 1.75rem;
                    padding: 0;
                    justify-content: center;
                    border-radius: 50%;
                    font: icon;
                    opacity: 0;
                    transition: .25s all;
                    &:hover {
                         background-color: #212529;
                    }
                    &:focus {
                         background-color: #212529;
                         outline: 0;
                         box-shadow: none;
                    }
                    
               }
          }
          .dropdown-menu {
               z-index: 1400;
          }
     }
     .list-view-header {

          position: -webkit-sticky;
          position: sticky;
          top: 0;
          z-index: 1020;
          display: flex;
          width: 100%;
          list-style: none;
          padding-left: 16px;
          padding-right: 16px;
          padding-bottom: .3rem;
          padding-top: .75rem;
          margin-bottom: 0;
          background-color: #555555;
          border-bottom: 1px solid #696969;
          > li {
               font-weight: bolder;
          }
          > ul {
               padding: 0;
               list-style: none;
               display: flex;
               font-weight: bolder;
          }
     
     }
}
.notification-screen {
     display: flex;
     height: 100%;
     -webkit-animation: .5s ease 0s normal forwards 1 fadein;
	animation: .5s ease 0s normal forwards 1 fadein;
}