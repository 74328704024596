.folder-tree {
     border-bottom: 1px solid #202124 ;
     .breadcrumb {
          margin-top: .5rem;
          margin-bottom: .5rem;
          max-height: 20px;
          overflow: hidden;
          .breadcrumb-item {
               font-size: .85rem;
               cursor: pointer;
               transition: .25s all;
               &:hover {
                    color: #FFF;
               }
               > span {
                    overflow: hidden;
                    max-width: 150px;
                    display: block;
                    text-overflow: ellipsis;
                    white-space: nowrap;
               }
             
          }
     }
}