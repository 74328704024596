.render-without-analyze {
     .form-check {
          font-size: .85rem;
     }
     >.card-body {
          max-height: calc(100vh - 160px - 2rem);
          padding-right: 0;
          overflow-y: scroll;
          &::-webkit-scrollbar {
               width: 16px;
               height: 16px;
          }
          &::-webkit-scrollbar-corner {
               background-color: transparent;
          }
          &::-webkit-scrollbar-thumb {
               background-color: #a7a7a7;
               min-height: 40px;
          }
          &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
               border: 4px solid transparent;
               background-clip: padding-box;
               border-radius: 8px;
          }
          &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-track {
               border: 4px solid transparent;
               background-clip: padding-box;
               border-radius: 8px;
          }
          &::-webkit-scrollbar-track {
               background-color: transparent;
          }
     }
    
     
     .nav-tabs .nav-link {
          padding: .8rem 1.5rem;
          border: none;
          font-weight: bold;
          border-radius: 5px;
          font-size: .9rem;
          margin-right: 1rem;
          color: #ededed;
     }
     .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
          color: #383838 ;
          background-color: #3dd598;
          border-radius: 5px;
     }
     .nav-link:focus, .nav-link:hover {
          color: #FFF;
          background-color: #383838;
          border-radius: 5px;
     }
     .nav.nav-tabs{
          border: none;
     }
}