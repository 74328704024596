.render-info {
     .job-name {
          display: flex;
          a {
               color: #d3d3d3;
               &:hover {
                    color:#3dd598;
                    cursor: pointer;
               }
          }
          span{
               display: block;
               white-space: nowrap; 
               width: 80%;
               padding-right: 2rem; 
               padding-left: .25rem; 
               overflow: hidden;
               text-overflow: ellipsis; 
          }
     }
}